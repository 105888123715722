import React from 'react';
import './App.css';

import NavBar from './Components/NavBar/NavBar';

//Pages
import About from './Components/About/About';
import Skills from './Components/Skills/Skills';
import Experience from './Components/Experience/Experience';
import Projects from './Components/Projects/Projects';

function App() {
    return (
        <div className="App">
            <NavBar />
            <div id="body">
                <About />
                <Skills />
                <Experience />
                {/*<Projects />*/}
            </div>
        </div>
    );
}

export default App;
