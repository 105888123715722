import React, {useState} from "react";
import './Experience.css';

import { 
    AiOutlineClose
} from "react-icons/ai";
import { 
    BiMinus,
    BiWindows 
} from "react-icons/bi";

export default function Experience() {
    var jobs = [
        {
            timeframe: '2014 - 2017',
            company: 'Robert E. Kennedy Library',
            title: 'Computer Support Specialist',
            description: 'Answered technical questions and debugged the library’s computers, printers, and scanners as well as checked-out electronic devices to the Cal Poly students and staff.'
        },
        {
            timeframe: '2017 - CURRENT',
            company: 'Tapestry Solutions, A Boeing Company/Boeing - San Luis Obispo',
            title: 'IT Engineer > Desktop Support Specialist > End User Support II',
            description: 'Developed full stack solutions for managing our computer inventory and tracking security patching, worked in Python to sync data between our JIRA and Salesforce systems, and helped develop scripts to detect evidences of virus infections and incorrect group configurations. Day to day tasks encompass hardware and software break-fixes, imaging automation, automated software package deployment and updating, asset management, server management, network troubleshooting, developing technical documentation, AD management, utilizing Rapid7 scan results to burndown vulnerabilities, and working with virtualized systems and VDI infrastructures.'
        },
        {
            timeframe: '+',
            company: 'Yours!',
            title: "?",
            description: 'If you are interested in expanding this list, reach out to me via email or LinkedIn! [See links in NavBar]'
        }
    ];
    const [selected, setSelected] = useState(jobs.length - 2);
    
    return (
        <div id="experience">
            <div className="notepad-header">
                Work Experience 
                <div className="notepad-header-controls">
                    <div className="notepad-icon-wrapper"><BiMinus /></div>
                    <div className="notepad-icon-wrapper"><BiWindows /></div>
                    <div className="notepad-icon-wrapper notepad-icon-wrapper-close"><AiOutlineClose /></div>
                </div>
            </div>
            <div className="tab-list">
                {
                    jobs.map((job,ind)=>(<div key={ind} className={ind === selected ? "tab tab-selected" : "tab"} onClick={()=>{setSelected(ind)}}>{job.timeframe}</div>))
                }
            </div>
            <div className="notepad-body">
                <ol>
                    <li><b>Company</b>: {jobs[selected].company}</li>
                    <li />
                    <li><b>Title</b>: {jobs[selected].title}</li>
                    <li />
                    <li><b>Description</b>:</li> 
                    {
                        jobs[selected].description.split('\n').map((line, ind)=>(<li key={ind}>{line}</li>))
                    }
                </ol>
            </div>
        </div>
    );
}