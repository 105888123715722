import React from "react";
import './SkillRating.css';

import {
    AiFillStar,
    AiOutlineStar
} from "react-icons/ai";

export default function SkillRating(props) {
    var filled = [...Array(4)].map((x, index)=>{
        return index < props.rating ? (<AiFillStar key={index} color="gold" size={23} className="rating-star"/>) : (<AiOutlineStar key={index} size={23} className="rating-star"/>)
    });
    var rating = ["Beginner","Intermediate","Advanced","Expert"]
    return (
        <div className="skillrating">
            {filled} <div className="skillrating-text">({rating[props.rating - 1]})</div>
        </div>
    );
}