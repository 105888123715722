import React, {useState} from "react";
import './Skills.css';

import { 
    VscChromeClose,
    VscChromeMaximize,
    VscChromeMinimize
 } from "react-icons/vsc";

import SkillModal from './SkillModal';
import SkillRating from "./SkillRating";

export default function Skills() {
    const [modal, setModal] = useState(false);
    const [selected, setSelected] = useState(null);

    var languages = [
        {
            name: "C",
            timeframe: "3 years",
            level: 3,
            notes: "Used to work with embedeed systems and robotic involving ESP32 and Arduino units."
        },
        {
            name: "CSS",
            timeframe: "4 years",
            level: 2,
            notes: "I know it technically isn't a language, but this is where people go to look for things like this."
        },
        {
            name: "HTML",
            timeframe: "4 years",
            level: 3,
            notes: "I know it technically isn't a language, but this is where people go to look for things like this."
        },
        {
            name: "Java",
            timeframe: "4 years",
            level: 2,
            notes: "Used throughout high school and college. Currently studying implementation of microservices in Spring."
        },
        {
            name: "Javascript",
            timeframe: "4 years",
            level: 3,
            notes: "Used frequently to develop full stack websites with Node and ReactJS."
        },
        {
            name: "Powershell",
            timeframe: "4 years",
            level: 3,
            notes: "Wrote scripts to manage IT resources as well as automate deployment of new machine images."
        },
        {
            name: "Python",
            timeframe: "3 years",
            level: 2,
            notes: "Used in data synchronization projects utililzing APIs and OAuth, filtering & organizing data in CSV files"
        },
        {
            name: "SQL",
            timeframe: "4 years",
            level: 2,
            notes: "Utilized MySQL, Postgres, and SQLite throughout course of college and career."
        }
    ];
    var frameworks = [
        {
            name: "Bootstrap",
            timeframe: "3 years",
            level: 2,
            notes: "My go-to for quick CSS formatting!"
        },
        {
            name: "ExpressJS",
            timeframe: "4 years",
            level: 3,
            notes: "Makes API development so much easier."
        },
        {
            name: "Material UI",
            timeframe: "3 years",
            level: 2,
            notes: "Used in a few personal projects such as utilities for quest tracking and battle management for D&D campaigns."
        },
        {
            name: "NodeJS",
            timeframe: "4 years",
            level: 3,
            notes: "The backbone of most of my web servers :)"
        },
        {
            name: "ReactJS",
            timeframe: "3 years",
            level: 3,
            notes: "Used to develop the front end for web projects at Tapestry as well as personal projects at home. I find it quite intuitive."
        }
    ];
    var platforms = [
        {
            name: "Citrix VDI",
            timeframe: "2 years",
            level: 2,
            notes: "Assisted in the migration to a VDI infastructure and assisted in the troubleshooting and management of the desktop instances."
        },
        {
            name: "Linux",
            timeframe: "7 years",
            level: 2,
            notes: "Used to host my websites"
        },
        {
            name: "Microsoft Windows",
            timeframe: "10 years",
            level: 3,
            notes: "Commonly use for Active Directory, DNS, DHCP, etc."
        },
        {
            name: "Microsoft Server",
            timeframe: "4 years",
            level: 3,
            notes: "Commonly use for Active Directory, DNS, DHCP, etc."
        },
        {
            name: "VMWare VSphere",
            timeframe: "5 years",
            level: 2,
            notes: "Spun up and managed server VMWare towers over the years."
        }
    ];
    var certifications = [
        {
            name: "AWS Cloud Practitioner",
            timeframe: "Dec 2021",
            notes: "Certification number available upon request"
        },
        {
            name: "CompTIA Security+",
            timeframe: "Apri 2021",
            notes: "Certification number available upon request"
        }
    ];

    return (
        <div id="skills">
            <div className="board-toolbar">
                <span className="board-toolbar-title">Skill Board</span>
                <div className="board-toolbar-buttons">
                    <div className="board-toolbar-icon"><VscChromeMinimize /></div>
                    <div className="board-toolbar-icon"><VscChromeMaximize /></div>
                    <div className="board-toolbar-icon"><VscChromeClose /></div>
                </div>
            </div>
            <div className="board-list">
                <div className="board">
                    <div className="board-header">Languages</div>
                    <div className="board-content">
                        {
                            languages.map((lang, key)=>(
                                <div className="board-tile" key={key} onClick={()=>{setSelected(lang);setModal(true);}}>
                                    <span className="board-tile-name">{lang.name}</span>
                                    <SkillRating rating={lang.level}/>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="board">
                    <div className="board-header">Frameworks</div>
                    <div className="board-content">
                        {
                            frameworks.map((frame, key)=>(
                                <div className="board-tile" key={key} onClick={()=>{setSelected(frame);setModal(true);}}>
                                    <span className="board-tile-name">{frame.name}</span>
                                    <SkillRating rating={frame.level}/>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="board">
                    <div className="board-header">Platforms</div>
                    <div className="board-content">
                        {
                            platforms.map((platform, key)=> (
                                <div className="board-tile" key={key} onClick={()=>{setSelected(platform);setModal(true);}}>
                                    <span className="board-tile-name">{platform.name}</span>
                                    <SkillRating rating={platform.level}/>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="board">
                    <div className="board-header">Certifications</div>
                    <div className="board-content">
                        {
                            certifications.map((cert, key)=> (
                                <div className="board-tile" key={key} onClick={()=>{setSelected(cert);setModal(true);}}>
                                    <span className="board-tile-name">{cert.name}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <SkillModal show={modal} skill={selected} onClose={()=>{setModal(false)}}/>
        </div>
    );
}