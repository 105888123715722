import React from "react";
import './SkillModal.css';

import SkillRating from "./SkillRating";

export default function ProjectModal(props) {
    var handleForegroundClick = (e) => {
        e.stopPropagation();
    }
    var handleBackgroundClick = () => {
        props.onClose();
    }
    return (
        <div className={props.show ? "skill-modal skill-modal-show" : "skill-modal"} onClick={handleBackgroundClick}>
            <div className="skill-modal-box" onClick={handleForegroundClick}>
                <div className="skill-modal-header">
                    <div className="skill-modal-title">
                        {props.skill && props.skill.name}
                    </div>
                    {
                        props.skill && props.skill.level && (
                            <div className="skill-modal-rating">
                                <SkillRating rating={props.skill.level}/>
                            </div>
                        )
                    }
                    <div className="skill-modal-timeframe">
                        {props.skill && props.skill.timeframe}
                    </div>
                </div>
                <div className="skill-modal-body">
                    {props.skill && props.skill.notes}
                </div>
                <div className="skill-modal-footer">
                    <button className="skill-modal-close" onClick={()=>{props.onClose()}}>Close</button>
                </div>
            </div>
        </div>
    );
}