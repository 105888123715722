import React, {useEffect, useState} from "react";
import './NavBar.css';

import { 
    SiGithub, 
    SiGmail, 
    SiLeetcode, 
    SiLinkedin 
} from "react-icons/si";
import {
    BiNotepad,
    BiTerminal
} from "react-icons/bi";
import {
    BsKanban
} from "react-icons/bs";
import { CgBrowser } from "react-icons/cg";

export default function NavBar() {
    const [ypos, setYPos] = useState(window.scrollY);
    const [downScroll, setDownScroll] = useState(false);
    
    const handleScroll = event => {
        var current = window.scrollY;
        if (current > ypos) {
            setDownScroll(true);
        }
        else if (current < ypos) {
            setDownScroll(false);
        }
        setYPos(current);
    }

    useEffect(()=> {
        setYPos(window.scrollY);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [ypos])

    return (
        <nav className={"navbar" + (downScroll ? " hiding-nav" : "")}>
            <img className="nav-icon" src="/pixel.png" alt="Profile" />
            <a href="#about"><BiTerminal /> About</a>
            <a href="#skills"><BsKanban /> Skills</a>
            <a href="#experience"><BiNotepad/> Experience</a>
            {/*<a href="#projects"><CgBrowser /> Projects</a>*/}
            <div className="nav-links">
                <a href="https://github.com/t3chn0manc3r" target="_blank"><SiGithub /></a>
                <a href="https://www.linkedin.com/in/andrew-t-gillis/" target="_blank"><SiLinkedin /></a>
                <a href="https://leetcode.com/t3chn0manc3r/" target="_blank"><SiLeetcode /></a>
                <a href="mailto: agillis.tech@gmail.com"><SiGmail /></a>
            </div>
        </nav>
    )
}